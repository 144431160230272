import React, { useContext, useEffect } from "react"

import Events from "@components/Events"
import {
  CanvasContainer,
  EndContent,
  AssetDescription,
  LandingContent,
  Timeline,
  OverlayContent,
  Footer,
  FastScrollOverlay,
  VideoOverlay,
  Head,
} from "@dom"

import { SiteContext } from "@context/siteContext"

export default function Index() {
  const { actions, transition, navOpen } = useContext(SiteContext)

  useEffect(() => {
    if (navOpen) actions.setBackground("black")
    if (!navOpen) actions.setBackground("white")
  }, [navOpen])
  useEffect(() => {
    if (!transition) actions.setTransition(true)
    setTimeout(() => {
      actions.setReady(true)
    }, 666)
    document.documentElement.style.overflow = "visible"
  }, [])
  return (
    <>
      <Head />
      <OverlayContent mode={"index"} />
      <div>
        <VideoOverlay />
        <EndContent />
      </div>
      <Footer />
    </>
  )
}
