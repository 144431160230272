/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef } from "react"
import { useGesture } from "react-use-gesture"
import { MathUtils } from "three"

import { RafManager } from "@helpers"
import { useGlobalContext, useMobileDevice } from "@hooks"

import { config } from "@data"

import useFastScrollBehavior from "./useFastScrollBehavior"

export default React.memo(function Events() {
  const domElement = useRef()
  const currentScroll = useRef(0)
  const prevScroll = useRef(0)

  const {
    progress,
    scrollLocked,
    mousePosition,
    currentMousePosition,
    cameraZ,
    cameraZMotionValue,
    fastScrollDestination,
  } = useGlobalContext()

  const { camera } = config
  const { any: isMobile } = useMobileDevice()
  const progressEase = camera.progressEase[isMobile ? "mobile" : "desktop"]
  const refEase = useRef(progressEase)

  const scrollLockedRef = useRef()
  scrollLockedRef.current = scrollLocked

  // TRIGGER FAST SCROLL / SKIP
  useFastScrollBehavior({
    progressEase,
    cameraEase: refEase,
    fastScrollDestination,
  })

  useEffect(() => {
    domElement.current = document.querySelector("#canvas-container")

    const animate = () => {
      // UPDATE SCROLL
      progress.current.smoothValue = MathUtils.lerp(
        progress.current.smoothValue,
        currentScroll.current,
        refEase.current,
      )
      progress.current.normalizedValue = MathUtils.clamp(
        progress.current.smoothValue / (domElement.current.offsetHeight - window.innerHeight),
        0,
        1,
      )
      if (scrollLockedRef.current) window.scrollTo(0, 0)

      // UPDATE MOTION VALUE

      // Update scroll progress from globalContext with requestAnimationFrame loop
      cameraZMotionValue.set(cameraZ.current)

      // UPDATE SPEED FOR EFFECTS
      const clp = MathUtils.clamp(
        (prevScroll.current - progress.current.smoothValue) * 0.005,
        -0.2,
        0.2,
      )
      progress.current.speed = MathUtils.lerp(progress.current.speed, clp, 0.15)
      progress.current.absoluteSpeed = Math.abs(progress.current.speed)
      progress.current.normalizedSpeed =
        (MathUtils.smoothstep(progress.current.speed, -0.2, 0.2) - 0.5) * 2.0

      prevScroll.current = progress.current.smoothValue

      // MOUSE
      mousePosition.current.x = MathUtils.lerp(
        mousePosition.current.x,
        currentMousePosition.current.x,
        0.075,
      )
      mousePosition.current.y = MathUtils.lerp(
        mousePosition.current.y,
        currentMousePosition.current.y,
        0.075,
      )
    }

    RafManager.add(animate)

    return () => {
      RafManager.remove(animate)
    }
  }, [])

  const domTarget = typeof window !== "undefined" ? window : null

  useGesture(
    {
      onScroll: ({ xy }) => {
        currentScroll.current = xy[1]
      },

      onMove: (s) => {
        currentMousePosition.current.x = (s.xy[0] / window.innerWidth) * 2 - 1
        currentMousePosition.current.y = -((s.xy[1] / window.innerHeight) * 2 - 1)
      },
    },
    {
      domTarget,
    },
  )
  return null
})
