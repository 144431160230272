import { useEffect } from "react"
import { gsap } from "gsap"

import { useGlobalContext, useGlobalAction } from "@hooks"

export default function useFastScrollBehavior({ progressEase, cameraEase, fastScrollDestination }) {
  const { isAppLoaded } = useGlobalContext()
  const { setNeedsFastScrollOverlay } = useGlobalAction()

  const goToDestination = (destination) => {
    if (!isAppLoaded) return

    const direction = destination > window.scrollY ? 1 : -1
    const movement = window.innerHeight * 1.5 * direction

    const isFar = Math.abs(destination - window.scrollY) > movement * 5
    const finalScrollLength =
      direction > 1 ? destination - movement * 4 : destination - movement * 2

    const setScrollEase = (v) => (cameraEase.current = v)

    if (isFar) {
      setNeedsFastScrollOverlay(true)
    }

    let o = { p: window.scrollY }
    let tl = gsap.timeline({
      onUpdate: () => {
        window.scrollTo(0, o.p)
      },
      onComplete: () => {
        setNeedsFastScrollOverlay(false)
      },
    })

    if (isFar) {
      // SCROLL, JUMP, SCROLL
      tl.to(o, { duration: 1.6, p: `+=${movement}`, ease: "Power2.easeIn" })
      tl.call(setScrollEase, [1])
      tl.set(o, { p: finalScrollLength })
      tl.to(o, { duration: 3, p: destination, delay: 0.5, ease: "Power2.easeInOut" })
      tl.call(setScrollEase, [progressEase], "-=2.5")
    } else {
      // ONLY SCROLL
      tl.to(o, { duration: 2, p: destination, ease: "Power3.easeInOut" })
    }
  }
  useEffect(() => {
    goToDestination(fastScrollDestination)
  }, [fastScrollDestination])
}
